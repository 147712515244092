import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';
import { RtlProvider } from 'root/components/Menus/shared/RtlProvider/RtlProvider';
import { dataHooks } from 'root/dataHooks';
import { MenusContextProvider } from 'root/contexts/MenusContext';

export const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  const { isRTL, isMobile } = useEnvironment();
  return (
    <MenusContextProvider value={{ isRTL, isMobile }}>
      <RtlProvider>
        <div data-hook={dataHooks.app.container}>{children}</div>
      </RtlProvider>
    </MenusContextProvider>
  );
};
