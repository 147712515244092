import { createContext, useContext } from 'react';

type ContextType = {
  isRTL: boolean;
  isMobile?: boolean;
};

export const MenusContext = createContext<ContextType>({} as ContextType);

export const MenusContextProvider = MenusContext.Provider;

export const useMenusContext = () => {
  const context = useContext(MenusContext);
  if (!context) {
    throw new Error('useMenusContext must be used within an MenusContextProvider');
  }
  return context;
};
