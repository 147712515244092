import React from 'react';
import { Badge, BadgePriority, Tooltip } from 'wix-ui-tpa/cssVars';
import type { Label as LabelType } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import { classes } from './Label.st.css';
import { ReactSVG } from 'react-svg';
import { useLabelLogic } from 'root/components/Menus/Label/Label.logic';

export const Label = ({ label }: { label: LabelType }) => {
  const { hideTooltip } = useLabelLogic();
  return (
    <Tooltip
      appendTo="window"
      className={classes.labelTooltip}
      content={label.name}
      contentClassName={classes.labelTooltipContent}
      disabled={hideTooltip}
      moveBy={{ x: 0, y: 4 }}
    >
      <Badge
        className={classes.label}
        data-hook={dataHooks.item.label}
        icon={<ReactSVG aria-label={label.name} className={classes.icon} src={label.icon?.url ?? ''} />}
        priority={BadgePriority.light}
      >
        <div className={classes.labelName}>{label.name}</div>
      </Badge>
    </Tooltip>
  );
};
